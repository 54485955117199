exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookies-index-js": () => import("./../../../src/pages/legal/cookies/index.js" /* webpackChunkName: "component---src-pages-legal-cookies-index-js" */),
  "component---src-pages-legal-policy-index-js": () => import("./../../../src/pages/legal/policy/index.js" /* webpackChunkName: "component---src-pages-legal-policy-index-js" */),
  "component---src-pages-portfolio-assekes-index-js": () => import("./../../../src/pages/portfolio/assekes/index.js" /* webpackChunkName: "component---src-pages-portfolio-assekes-index-js" */),
  "component---src-pages-portfolio-begin-index-js": () => import("./../../../src/pages/portfolio/begin/index.js" /* webpackChunkName: "component---src-pages-portfolio-begin-index-js" */),
  "component---src-pages-portfolio-dau-index-js": () => import("./../../../src/pages/portfolio/dau/index.js" /* webpackChunkName: "component---src-pages-portfolio-dau-index-js" */),
  "component---src-pages-portfolio-la-tribu-index-js": () => import("./../../../src/pages/portfolio/laTribu/index.js" /* webpackChunkName: "component---src-pages-portfolio-la-tribu-index-js" */),
  "component---src-pages-portfolio-periquette-index-js": () => import("./../../../src/pages/portfolio/periquette/index.js" /* webpackChunkName: "component---src-pages-portfolio-periquette-index-js" */),
  "component---src-pages-portfolio-smallgran-index-js": () => import("./../../../src/pages/portfolio/smallgran/index.js" /* webpackChunkName: "component---src-pages-portfolio-smallgran-index-js" */),
  "component---src-pages-portfolio-voltereta-index-js": () => import("./../../../src/pages/portfolio/voltereta/index.js" /* webpackChunkName: "component---src-pages-portfolio-voltereta-index-js" */)
}

